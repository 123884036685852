import React, { useMemo } from 'react';

import { Loading, useEditController } from 'react-admin';
import { PROMOTION_TYPES } from './constants';
import EditFormPoints from './EditFormPoints';
import EditFormWithdrawal from './EditFormWithdrawal';
import EditFormHomeDelivery from './EditFormHomeDelivery';
import EditFormStand from './EditFormStand';

const PromotionEdit = ({ permissions, ...props }) => {
  const { record, loading } = useEditController(props);

  const type = useMemo(() => record?.promotionType?.name, [record]);

  if (loading) {
    return <Loading />;
  }

  switch (type) {
    case PROMOTION_TYPES.WITH_POINTS:
      return <EditFormPoints {...props} />;
    case PROMOTION_TYPES.REDEMPTION_POINT:
      return <EditFormWithdrawal {...props} />;
    case PROMOTION_TYPES.HOME_DELIVERY:
      return <EditFormHomeDelivery {...props} />;
    case PROMOTION_TYPES.STAND:
      return <EditFormStand {...props} />;
    default:
      return null;
  }
};

export default PromotionEdit;
