import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';

import Form from './Form';
import SurveyToolbar from './SurveyToolbar';
import { validateSurveyForm } from '../../lib/utils/surveys';

const SurveyCreate = (props) => {
  const [input, setInput] = useState(undefined);

  const validate = () => validateSurveyForm(input);

  return (
    <Create {...props}>
      <SimpleForm
        redirect="edit"
        toolbar={<SurveyToolbar isEdit={false} input={input} />}
        validate={validate}
        validateOnBlur
      >
        <Form setInput={setInput} isEdit={false} {...props} />
      </SimpleForm>
    </Create>
  );
};

export default SurveyCreate;
