import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import { useFormState } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  override: {
    '&.MuiToolbar-root': {
      justifyContent: 'space-between',
    },
  },
}));

const SurveyToolbar = (props) => {
  const { valid } = useFormState();
  const classes = useStyles();
  const {
    saving, input,
  } = props;
  return (
    <Toolbar {...props} className={classes.override}>
      <SaveButton
        label="Save"
        disabled={saving || !valid}
        transform={() => ({ ...input })}
      />
    </Toolbar>
  );
};

export default SurveyToolbar;
