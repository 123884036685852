import React, { useState, useEffect } from 'react';
import {
  FormDataConsumer,
  DateInput,
  TextInput,
  useTranslate,
  SelectInput,
  BooleanInput,
  CheckboxGroupInput,
  ArrayInput,
  SimpleFormIterator,
  required,
} from 'react-admin';
import { useFormState, useForm } from 'react-final-form';

import { Box, Toolbar, Typography } from '@material-ui/core';
import CreateBtn from './buttons/CreateBtn';
import EditBtn from './buttons/EditBtn';
import UserValidation from './UserValidation';
import {
  binaryOptions, childComponentOptions,
  dietOptions,
  genderOptions, homeComponentOptions, interestOptions, lifestyleOptions, petsOptions,
} from '../../lib/utils/profile';


const ProfileFormUi = (props) => {
  const { setSaveLoading, setProfile } = props;
  const translate = useTranslate();
  const { errors, values } = useFormState();
  const { change: changeFieldValue } = useForm();
  const [errorHome, setErrorHome] = useState(false);
  const [errorChild, setErrorChild] = useState(false);

  useEffect(() => {
    const childrenCount = parseInt(values?.numberOfChildren, 10);
    const childrenListCount = values?.children?.length;
    const homeCount = parseInt(values?.numberOfHomeComponents, 10);
    const selectedLimit = -1;
    const atLeastOne = 0;
    const isErrorHome = (childrenCount > selectedLimit)
      && (homeCount > selectedLimit) && (childrenCount >= homeCount);
    const isErrorChild = (childrenCount > atLeastOne) && !(childrenCount === childrenListCount);
    setErrorHome(isErrorHome);
    setErrorChild(isErrorChild);
  }, [values, errors]);

  useEffect(() => {
    const childrenCount = parseInt(values?.numberOfChildren, 10);
    const childrenListCount = values?.children?.length;
    if (childrenCount !== childrenListCount) {
      const zero = 0;
      const concordantValue = values?.children?.slice(zero, childrenCount);

      if (childrenCount > childrenListCount) {
        const diff = childrenCount - childrenListCount;
        const sum = 1;

        for (let i = zero; i < diff; i += sum) {
          concordantValue.push(undefined);
        }
      }
      changeFieldValue('children', concordantValue);
    }
    /* eslint-disable-next-line */
  }, [values?.numberOfChildren]);

  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h2" component="h2">
        {translate('user.edit.title1')}
      </Typography>
      <SelectInput
        fullWidth
        source="gender"
        choices={genderOptions(translate)}
        label={translate('user.edit.gender.title')}
      />
      <TextInput
        source="identifier"
        label={translate('user.edit.identifier')}
        fullWidth
      />
      <DateInput
        fullWidth
        validate={UserValidation.validateActive}
        source="birthDate"
        label={translate('user.edit.birth')}
      />
      <Typography variant="h2" component="h2">
        {translate('user.edit.title2')}
      </Typography>
      <TextInput
        source="address.line1"
        label={translate('user.edit.address_1')}
        fullWidth
      />
      <TextInput
        source="address.line2"
        label={translate('user.edit.address_3')}
        fullWidth
      />
      <TextInput
        source="address.administrativeAreaLevel1"
        label={translate('user.edit.city')}
        fullWidth
      />
      <TextInput
        source="address.administrativeAreaLevel2"
        label={translate('user.edit.province')}
        fullWidth
      />
      <TextInput
        source="address.administrativeAreaLevel3"
        label={translate('user.edit.address_2')}
        fullWidth
      />
      <SelectInput
        fullWidth
        source="numberOfHomeComponents"
        choices={homeComponentOptions}
        label={translate('user.edit.home')}
        options={{ error: errorHome }}
      />
      {errorHome && (
      <p
        className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled"
        id="numberOfHomeComponents-helper-text"
      >The number of people in the household must be greater than the number of children
      </p>
      )}
      <SelectInput
        fullWidth
        source="numberOfChildren"
        choices={childComponentOptions}
        label={translate('user.edit.numberOfChildren')}
      />
      <ArrayInput source="children" label="">
        <SimpleFormIterator
          TransitionProps={{ enter: false, exit: false }}
          disableAdd
          disableReordering
        >
          <DateInput validate={[required('Enter the date of birth')]} source="birthDate" label={translate('user.edit.birthDate')} />
        </SimpleFormIterator>
      </ArrayInput>
      {errorChild && (
        <p
          className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled"
          id="children-helper-text"
        >The number of children must match.
        </p>
      )}
      <SelectInput
        fullWidth
        source="hasPet"
        choices={binaryOptions(translate)}
        label={translate('user.edit.has_pet')}
      />
      <FormDataConsumer>
        {({ formData }) => (formData.hasPet === 'YES' && (
          <CheckboxGroupInput
            source="petsValues"
            validate={[required()]}
            choices={petsOptions(translate)}
            label={translate('user.edit.pets')}
            fullWidth
          />
        ))}
      </FormDataConsumer>
      <Typography variant="h2" component="h2">
        {translate('user.edit.title3')}
      </Typography>
      <CheckboxGroupInput
        source="lifestylesValues"
        choices={lifestyleOptions(translate)}
        label={translate('user.edit.lifestyles')}
        fullWidth
      />
      <SelectInput
        source="dietValue"
        choices={dietOptions(translate)}
        label={translate('user.edit.diet')}
        fullWidth
      />
      <CheckboxGroupInput
        source="interestsValues"
        choices={interestOptions(translate)}
        label={translate('user.edit.interest')}
        fullWidth
      />
      <SelectInput
        fullWidth
        source="hasAlcohol"
        choices={binaryOptions(translate)}
        label={translate('user.edit.has_alcohol')}
      />
      <BooleanInput
        label={translate('user.edit.communication')}
        source="platformCommunications"
        fullWidth
      />
      <DateInput
        source="updatedAt"
        disabled
        label={translate('user.edit.updatedAt')}
        fullWidth
      />
      <Toolbar>
        <Box display="flex">
          <FormDataConsumer>
            {({ formData, ...rest }) => (formData.createdMode === true
              ? (
                <CreateBtn
                  form={formData}
                  customLoad={setSaveLoading}
                  customProfile={setProfile}
                  errorHomeCom={errorHome || errorChild}
                  {...rest}
                />
              )
              : (
                <EditBtn
                  form={formData}
                  customLoad={setSaveLoading}
                  customProfile={setProfile}
                  errorHomeCom={errorHome || errorChild}
                  {...rest}
                />
              )
            )}
          </FormDataConsumer>
        </Box>
      </Toolbar>
    </div>
  );
};

export default ProfileFormUi;
