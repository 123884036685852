import { makeStyles } from '@material-ui/core/styles';

const surveyStyles = makeStyles(() => ({
  questionsWrapper: {
    boxSizing: 'border-box',
    padding: '15px 10px',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px 0',
    fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif',
  },
  questionsErrorWrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '0 10px',
    color: '#f44336',
  },
  questionContainer: {
    display: 'grid',
    gridTemplateColumns: '2% 15% auto 2%',
    alignItems: 'center',
    gap: '0 25px',
  },
  questionListContainer: {
    display: 'grid',
    gridTemplateColumns: '90% auto 3%',
    placeItems: 'center',
    gap: '0 15px',
  },
  questionListAccordion: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 'unset',
      width: '100%',
    },
  },
  questionListAnswersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px 0',
  },
  pointer: {
    cursor: 'pointer',
    padding: '5px',
    borderRadius: '50%',
    transition: 'all 0.3s ease',

    '&:hover': {
      background: 'rgba(0, 0, 0, .15)',
    },
  },
  answersContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '0 10px',
  },
  draggingListItem: {
    background: '#3FA1EA',
  },
  addQuestionContainer: {
    margin: '15px 0',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '0 10px',
    cursor: 'pointer',
    width: 'max-content',
  },
}));

export default surveyStyles;
