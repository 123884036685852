import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import 'react-quill/dist/quill.snow.css';
import { addField, FieldTitle } from 'ra-core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
} from 'react-admin';
import {
  InputLabel, FormControl, withStyles, Select, DialogActions, Button, DialogContentText, MenuItem,
} from '@material-ui/core';

const styles = {
  label: {
    position: 'relative',
  },
};

const useStyles = makeStyles(() => ({
  menuItem: {
    marginBottom: 20,
  },
}));

const StatusDropdownField = (props) => {
  const {
    meta: { touched, error },
    label,
    id,
    source,
    resource,
    isRequired,
    choices,
    className,
    setStatus,
    input: { value },
    currStatus,
  } = props;

  const translate = useTranslate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentSelectedStatus, setCurrentSelectedStatus] = React.useState(null);
  const [selectValue, setSelectValue] = React.useState(value);
  const classes = useStyles();

  const handleValueChange = (val) => {
    const { input: { onChange } } = props;
    onChange(val);
    setStatus(val);
  };

  React.useEffect(() => {
    if (currStatus) {
      const { input: { onChange } } = props;
      setCurrentSelectedStatus(currStatus);
      setSelectValue(currStatus);
      onChange(currStatus);
    }
    /* eslint-disable-next-line */
  }, [currStatus]);

  React.useEffect(() => {
    if (value && value !== selectValue) {
      setSelectValue(value);
      setCurrentSelectedStatus(currStatus);
    }
    /* eslint-disable-next-line */
  }, [value]);

  return (
    <>
      <FormControl
        error={!!(touched && error)}
        className={className}
      >
        {label !== '' && label !== false && (
        <InputLabel shrink htmlFor={id}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
        )}
        <Select
          id="grouped-select"
          value={selectValue}
          onChange={(data) => {
            setCurrentSelectedStatus(data.target.value);
            setOpenDialog(true);
          }}
          className={classes.menuItem}
        >
          {
            choices.map(choice => (
              <MenuItem
                value={choice.key}
                key={choice.key}
              >{`${choice.code}. ${choice.status}`}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <Dialog
        fullWidth
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate('promotion.status.changeStatusTitle')}
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            {translate('promotion.status.changeStatusNegative')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleValueChange(currentSelectedStatus);
              setOpenDialog(false);
            }}
          >
            {translate('promotion.status.changeStatusPositive')}
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
};

const StatusDropdown = addField(withStyles(styles)(StatusDropdownField));

StatusDropdown.propTypes = {
  input: PropTypes.object,
  source: PropTypes.string,
};

StatusDropdown.defaultProps = {
  addLabel: false,
  fullWidth: true,
};
export default StatusDropdown;
