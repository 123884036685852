import React, { useState, useEffect, cloneElement } from 'react';

import {
  ArrayInput,
  FormDataConsumer,
  SimpleForm,
  maxLength,
  SimpleFormIterator,
  TextInput,
  useEditController,
  useTranslate,
  Loading,
  Edit,
  Toolbar,
  SaveButton,
  useNotify,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { cloneDeep } from 'lodash';
import { Prompt } from 'react-router-dom';
import { FormLabel, Button } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  PROMO_EAN_MAX_FIELDS,
  PROMO_EAN_MAX_LENGTH,
  ONE,
  ACTIVE_STATUS,
  AUTO_PUBLISH_STATUS,
  STATUS,
  RADIX_TEN,
} from './constants';

import {
  validatePromotionFields,
  PromotionPointsValidation,
  validateDateStartDate,
  PromotionMandatory,
  validateDateEndDate,
  validateSafetyStock,
  validateRetailers,
  validateOrderValidity,
  validateOrderCancellation,
} from './PromotionUtils';

import RichTextEditor from '../../lib/components/RichTextEditor';
import UploadImage from './HandleImage/UploadImage';
import StatusDropdown from './StatusDropdown';
import { TabbedLayout, Tab } from '../../lib/components';
import OrderReferenceList from './OrderReferenceList';
import StatusDateInput from './StatusDateInput';
import NumericInput from './NumericInput';
import MultiSelectRetailer from './MultiSelectRetailer';

import { promotionFormStyles } from './promotionFormStyles';
import CreateSurveyButton from './CreateSurveyButton';
import UploadCouponCodesButton from './UploadCouponCodesButton';
import PromotionSegmentation from './PromotionSegmentation';

const IMAGE_WIDTH = 400;
const IMAGE_HEIGHT = 400;
const REGEX_EAN = /^.*?\[[^\d]*(\d+)[^\d]*\].*$/;
const DETAIL = 'DETAIL';
const ORDER = 'ORDER';
const SEGMENTATION = 'SEGMENTATION';
const MIN_POINTS = 0;

let recordData;

/*
  Custom Edit Purpose : Save the server response for comparison.
  StartDate and  EndDate may contain past date at the time of editig.
  In this situation user can select the current date or future date  or The past date itself.
  To accomplish this functionality we are saving server response to recordData
*/


const CustomEdit = (props) => {
  const {
    record,
    ...rest
  } = useEditController(props);
  const [selectedTab, setSelectedTab] = useState(DETAIL);
  const { children, resetRecordValues } = props;
  const translate = useTranslate();
  const classes = promotionFormStyles();
  recordData = record;

  return (
    <div className={classes.root}>
      <TabbedLayout>
        <Tab
          label={translate('user.tabs.promoDetail')}
          onClick={() => {
            if (selectedTab !== DETAIL) {
              resetRecordValues();
              setSelectedTab(DETAIL);
            }
          }}
        >
          <Card>
            {cloneElement(children, {
              record,
              ...rest,
            })}
          </Card>
        </Tab>
        <Tab
          label={translate('user.tabs.orders')}
          path="orders"
          onClick={() => {
            setSelectedTab(ORDER);
          }}
        >
          {
            !recordData && <Loading />
          }
          {recordData && recordData.id
            && <OrderReferenceList promotionId={recordData.id} {...props} />
          }
        </Tab>
        <Tab
          label={translate('user.tabs.promoSegmentation')}
          path="segmentation"
          onClick={() => {
            setSelectedTab(SEGMENTATION);
          }}
        >
          {
            !recordData && <Loading />
          }
          {recordData && recordData.uuid
            && <PromotionSegmentation promotionUuid={recordData.uuid} />
          }
        </Tab>
      </TabbedLayout>
    </div>
  );
};

const RemoveEanButton = withStyles(theme => ({
  root: {
    color: theme.overrides.MuiButton.root.color,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))(Button);

const EditFormPoints = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const {
    record,
  } = useEditController(props);
  const [validationProvider, setValidationProvider] = useState(PromotionPointsValidation);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currStatus, setCurrStatus] = useState(null);
  const [initialStock, setInitialStock] = useState(null);
  const [recordValues, setRecordValues] = useState(null);
  const [eansLength, setEansLength] = useState(null);
  const [surveyError, setSurveyError] = useState(null);
  const [childProcessing, setChildProcessing] = useState(false);
  const [childCodes, setChildCodes] = useState(null);
  const notify = useNotify();
  const classes = promotionFormStyles();
  const mustStatus = [ACTIVE_STATUS, AUTO_PUBLISH_STATUS];

  const updateRecordVal = (key, val) => {
    const newRecordValue = { ...recordValues, [key]: val.target?.value };
    setRecordValues(newRecordValue);
  };

  useEffect(() => {
    setStartDate(record?.startDate);
    setEndDate(record?.endDate);
    setCurrStatus(record?.status?.key);
    setInitialStock(record?.promotionStock?.initialStock);
    setRecordValues(record);
    setEansLength(record?.eans?.length);
    setChildCodes(record?.couponCodesStats);
  }, [record]);

  useEffect(() => {
    const statusKey = currStatus || record?.status?.key;
    if (!mustStatus.includes(statusKey)) {
      setValidationProvider(PromotionPointsValidation);
    } else if (mustStatus.includes(statusKey)) {
      setValidationProvider(PromotionMandatory);
    }
    /* eslint-disable-next-line */
  }, [currStatus]);

  useEffect(() => {
    if (childCodes !== null) {
      const { total, distributed, notDistributed } = childCodes;

      const elementCouponsTotal = document.getElementById('coupons-total');
      const elementCouponsDistributed = document.getElementById('coupons-distributed');
      const elementCouponsNotDistributed = document.getElementById('coupons-notDistributed');
      if (elementCouponsTotal) {
        elementCouponsTotal.value = total;
      }
      if (elementCouponsDistributed) {
        elementCouponsDistributed.value = distributed;
      }
      if (elementCouponsNotDistributed) {
        elementCouponsNotDistributed.value = notDistributed;
      }
    }
  }, [childCodes]);

  const onFailure = (error) => {
    const INCOMPLETE_SURVEY = 'GraphQL error: Survey for this promotion is incomplete.';
    const NO_SURVEY = 'GraphQL error: This promotion doesnt have an associated survey.';

    if (error.message === INCOMPLETE_SURVEY) {
      setSurveyError(translate('promotion.survey.incompleteError'));
      notify(translate('promotion.survey.incompleteError'), 'warning');
    } else if (error.message === NO_SURVEY) {
      setSurveyError(translate('promotion.survey.notCreatedError'));
      notify(translate('promotion.survey.notCreatedError'), 'warning');
    } else {
      notify(error.message, 'warning');
    }
  };

  const onSuccess = () => {
    setSurveyError(null);
    notify(translate('promotion.edit.update'));
  };

  const InformationView = () => (
    <Grid>
      <Grid item>
        <FormLabel className={classes.helpLabel}>{translate('image.upload.format')}</FormLabel>
        <FormLabel className={classes.helpContent}>{translate('image.upload.formatValue')}</FormLabel>
      </Grid>
      <Grid item>
        <FormLabel className={classes.helpLabel}>{translate('image.upload.weight')}</FormLabel>
        <FormLabel className={classes.helpContent}>{translate('image.upload.weightValue')}</FormLabel>
      </Grid>
      <Grid item>
        <FormLabel className={classes.helpLabel}>{translate('image.upload.size')}</FormLabel>
        <FormLabel className={classes.helpContent}>{translate('image.upload.sizeValue')}</FormLabel>
      </Grid>
    </Grid>
  );


  const PromotionToolBar = promProps => (
    <Toolbar {...promProps} className={classes.fixedSaveButton}>
      <SaveButton disabled={childProcessing === true} />
    </Toolbar>
  );

  const PromptUnSaved = () => (
    <Prompt
      when={JSON.stringify(record) !== JSON.stringify(recordValues)}
      message={translate('promotion.edit.alert')}
    />
  );

  const updateEans = (val) => {
    const index = parseInt(REGEX_EAN.exec(val.target.id)[ONE], RADIX_TEN);
    const eans = cloneDeep(recordValues.eans);
    if (eans.length - ONE < index) {
      eans.push({
        value: val.target.value,
      });
    } else {
      eans[index].value = val.target.value;
    }
    updateRecordVal('eans', { target: { value: eans } });
    setEansLength(eans.length);
  };

  const resetRecordValues = () => {
    setRecordValues(record);
  };


  const handleOnRemoveEan = () => {
    const ONE_ELEMENT = 1;
    setEansLength(eansLength - ONE_ELEMENT);
  };

  const customRemoveButton = () => (
    <RemoveEanButton
      variant="contained"
      color="default"
      startIcon={<RemoveCircleOutlineIcon />}
      onClick={() => handleOnRemoveEan(recordValues)}
    >
      Remove
    </RemoveEanButton>
  );

  return (
    <CustomEdit {...props} undoable={false} resetRecordValues={resetRecordValues}>
      <Edit
        {...props}
        onSuccess={onSuccess}
        onFailure={onFailure}
        actions={null}
        undoable={false}
        title={`Promotion #${record?.key}`}
      >
        <SimpleForm
          redirect={false}
          validate={(value) => {
            validatePromotionFields(value);
          }}
          data-testid="promotion-edit-form"
          toolbar={<PromotionToolBar />}
        >
          <PromptUnSaved recordValues={recordValues} />
          <div className={classes.form}>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLiteCentered}>
                  <InfoTwoToneIcon />
                  <p>{translate('promotion.edit.promotionType')}: {record?.promotionType?.name}</p>
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.name')}</div>
                </div>
                <div className={classes.normalBox}>
                  <TextInput
                    source="name"
                    fullWidth
                    validate={validationProvider.promotionName}
                    resettable
                    label={translate('promotion.edit.name')}
                    onChange={val => updateRecordVal('name', val)}
                  />
                </div>
              </div>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.status')}</div>
                </div>
                <div className={classes.normalBox}>
                  <StatusDropdown
                    source="status.key"
                    label={translate('promotion.edit.status')}
                    choices={STATUS}
                    className={classes.formControlFields}
                    currStatus={currStatus}
                    setStatus={(status) => {
                      setCurrStatus(status);
                      updateRecordVal('status.key', { target: { value: status } });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.eans')}</div>
                </div>
                <div className={classes.eanBox}>
                  <FormDataConsumer>
                    {({ formData: { eans } }) => (
                      <Grid item sm={12} md={10} lg={4}>
                        <ArrayInput
                          source="eans"
                          label=""
                          onChange={updateEans}
                        >
                          <SimpleFormIterator
                            disableReordering
                            disableAdd={eans && eans.length >= PROMO_EAN_MAX_FIELDS}
                            className={classes.iteratorFormItem}
                            removeButton={customRemoveButton()}
                          >
                            <TextInput
                              label=""
                              source="value"
                              validate={maxLength(PROMO_EAN_MAX_LENGTH)}
                            />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </Grid>
                    )
                    }
                  </FormDataConsumer>
                </div>
              </div>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.image')}</div>
                </div>
                <div className={classes.imageBox}>
                  <FormDataConsumer>
                    {({ formData }) => (
                      <UploadImage
                        {...props}
                        id={formData.uuid}
                        source="imageUrl"
                        accept="image/jpeg,image/png,image/jpg"
                        originalUrl={formData.imageUrl}
                        maxSize={5000000}
                        customSource="imagePromo"
                        previewWidthAndHeight={{ width: 250, height: 'auto' }}
                        label={translate('image.promotionImage')}
                        placeholder={<p>{translate('image.ImageDropLabel')}</p>}
                        maxImageWidth={IMAGE_WIDTH}
                        maxImageHeight={IMAGE_HEIGHT}
                        validate={validationProvider.promotionImageUpload}
                        informationComponent={() => <InformationView />}
                        onChange={updateRecordVal}
                      />
                    )}
                  </FormDataConsumer>
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.surveyButton')}</div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.buttonContainer}>
                    <CreateSurveyButton
                      recordValues={recordValues}
                      recordData={recordData}
                      eansLength={eansLength}
                      errorMessage={surveyError}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.surveyInfo')}</div>
                </div>
                <div className={classes.normalBoxLite}>
                  <p>{translate('promotion.edit.createSurveyInfo')}</p>
                </div>
                <div className={classes.normalBoxLite}>
                  <p>{translate('promotion.edit.editSurveyInfo')}</p>
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.points')}</div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <NumericInput
                      source="points"
                      label={translate('promotion.edit.points')}
                      type="number"
                      className={classes.formControlFields}
                      min={MIN_POINTS}
                      validate={validationProvider.promotionPoints}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.rowCol} />
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.couponCodes')}</div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <NumericInput
                      fullWidth
                      id="coupons-total"
                      source="couponCodesStats.total"
                      label={translate('promotion.edit.couponCodesStats.total')}
                      resettable
                      className={classes.formControlFields}
                      disabled
                    />
                  </div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <NumericInput
                      fullWidth
                      id="coupons-distributed"
                      source="couponCodesStats.distributed"
                      label={translate('promotion.edit.couponCodesStats.distributed')}
                      resettable
                      className={classes.formControlFields}
                      disabled
                    />
                  </div>
                  <div className={classes.normalBoxRight}>
                    <NumericInput
                      fullWidth
                      id="coupons-notDistributed"
                      source="couponCodesStats.notDistributed"
                      label={translate('promotion.edit.couponCodesStats.notDistributed')}
                      resettable
                      className={classes.formControlFields}
                      validate={validationProvider.promotionCodes}
                      onChange={updateRecordVal}
                      disabled
                    />
                  </div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.buttonContainer}>
                    <UploadCouponCodesButton
                      recordValues={recordValues}
                      recordData={recordData}
                      errorMessage={surveyError}
                      passChildProcessing={setChildProcessing}
                      passChildCodes={setChildCodes}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.stock')}</div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <NumericInput
                      fullWidth
                      source="promotionStock.initialStock"
                      resettable
                      label={translate('promotion.edit.initialStock')}
                      type="number"
                      className={classes.formControlFields}
                      onValueChanged={(value) => {
                        updateRecordVal('promotionStock.initialStock', {
                          target: { value },
                        });
                        setInitialStock(value);
                      }}
                      validate={validationProvider.promotionInitialStock}
                      status={currStatus}
                    />
                  </div>
                  <div className={classes.normalBoxRight}>
                    <NumericInput
                      fullWidth
                      source="promotionStock.safetyStock"
                      resettable
                      label={translate('promotion.edit.safetyStock')}
                      maxValue={100}
                      type="number"
                      className={classes.formControlFields}
                      disabled={!initialStock}
                      validate={
                        safetyStock => validateSafetyStock(safetyStock, initialStock, translate)
                      }
                      status={currStatus}
                      checkInitialStock
                      initialStock={initialStock}
                      onValueChanged={(value) => {
                        updateRecordVal('promotionStock.safetyStock', {
                          target: { value },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <NumericInput
                      fullWidth
                      source="promotionStock.availableStock"
                      resettable
                      label={translate('promotion.edit.availableStock')}
                      className={classes.formControlFields}
                      disabled
                    />
                  </div>
                  <div className={classes.normalBoxRight}>
                    <NumericInput
                      fullWidth
                      source="promotionStock.realStock"
                      resettable
                      label={translate('promotion.edit.realStock')}
                      className={classes.formControlFields}
                      disabled
                    />
                  </div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <NumericInput
                      fullWidth
                      source="promotionStock.pendingStock"
                      resettable
                      label={translate('promotion.edit.pendingStock')}
                      className={classes.formControlFields}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.richTexts')}</div>
                </div>
                <div className={classes.bigBox}>
                  <RichTextEditor
                    multiline
                    fullWidth
                    source="description"
                    resettable
                    validate={validationProvider.promotionDescription}
                    label={translate('promotion.edit.description')}
                    curValue={record?.description}
                    onChangeValue={val => updateRecordVal('description', val)}
                  />
                </div>
                <div className={classes.bigBox}>
                  <RichTextEditor
                    multiline
                    fullWidth
                    source="legalText"
                    resettable
                    validate={validationProvider.promotionLegalText}
                    label={translate('promotion.edit.legalText')}
                    curValue={record?.legalText}
                    onChangeValue={val => updateRecordVal('legalText', val)}
                  />
                </div>
              </div>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.retailers')}</div>
                </div>
                <div className={classes.retailers}>
                  <FormDataConsumer>
                    {({ formData: { retailers, retailersList } }) => (
                      <MultiSelectRetailer
                        source="retailers"
                        label={translate('promotion.edit.retailers')}
                        retailers={retailers || []}
                        retailersList={retailersList || []}
                        className={classes.formControlFields}
                        validate={val => validateRetailers(val, currStatus)}
                        onChangeValue={(value) => {
                          updateRecordVal('retailers', {
                            target: { value },
                          });
                        }}
                      />
                    )
                    }
                  </FormDataConsumer>
                </div>
              </div>
            </div>
            <div className={classes.mainRow}>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.dates')}</div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <StatusDateInput
                      source="startDate"
                      validate={date => validateDateStartDate(date, endDate, currStatus,
                        record?.startDate, record?.status?.key)}
                      label={translate('promotion.edit.startAt')}
                      onChangeDate={(date) => {
                        setStartDate(date.target.value);
                        updateRecordVal('startDate', date);
                      }}
                      currStatus={currStatus}
                      setCurrStatus={setCurrStatus}
                    />
                  </div>
                  <div className={classes.normalBoxRight}>
                    <StatusDateInput
                      source="endDate"
                      validate={date => validateDateEndDate(date, startDate)}
                      label={translate('promotion.edit.endAt')}
                      onChangeDate={(date) => {
                        setEndDate(date.target.value);
                        updateRecordVal('endDate', date);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.rowCol}>
                <div className={classes.normalBoxLite}>
                  <div className={classes.sectionTitle}>{translate('promotion.edit.titles.order')}</div>
                </div>
                <div className={classes.normalBox}>
                  <div className={classes.normalBoxLeft}>
                    <NumericInput
                      fullWidth
                      source="daysForOrdersValidity"
                      resettable
                      label={translate('promotion.edit.orderValidity')}
                      type="number"
                      className={classes.numInput}
                      validate={
                        orderValidity => validateOrderValidity(orderValidity, currStatus, translate)
                      }
                    />
                  </div>
                  <div className={classes.normalBoxRight}>
                    <NumericInput
                      fullWidth
                      source="daysUntilOrderCancellation"
                      resettable
                      label={translate('promotion.edit.orderCancellation')}
                      type="number"
                      className={classes.numInput}
                      validate={value => validateOrderCancellation(value, translate)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimpleForm>
      </Edit>
    </CustomEdit>
  );
};

export default EditFormPoints;
