import React, { useLayoutEffect, useMemo } from 'react';

import FormUi from './FormUi';
import { parseQuestionsNodeInput } from '../../lib/utils/surveys';

const FormCreate = (props) => {
  const {
    status,
    surveyName,
    random,
    recordQuestions,
    setInput,
  } = props;

  const parsedInput = useMemo(() => ({
      input: {
        active: status,
        name: surveyName,
        options: {
          randomEnabled: random,
        },
        questions: parseQuestionsNodeInput(recordQuestions),
      },
    }
  ), [status, surveyName, random, recordQuestions]);

  useLayoutEffect(() => {
    setInput(parsedInput);
    /* eslint-disable-next-line */
  }, [parsedInput]);

  return (<FormUi {...props} />);
};

export default FormCreate;
