import React from 'react';
import { ListButton, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import {
  Box,
  FormControlLabel,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Questions from './Questions/Questions';
import ConfirmDelete from './Questions/ConfirmDelete';
import surveyStyles from './surveyStyles';

const FormUi = (props) => {
  const {
    touched,
    addTouched,
    surveyName,
    setSurveyName,
    status,
    toggleStatus,
    random,
    toggleRandom,
    createQuestion,
  } = props;
  const t = useTranslate();
  const { errors } = useFormState();
  const classes = surveyStyles();
  const globalFont = { fontFamily: 'Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif' };

  return (
    <>
      <ListButton style={{ width: '60px' }} />
      <h3 style={{ ...globalFont }}>{t('surveys.edit.info')}</h3>
      <Box display="flex" justifyContent="space-between" style={{ width: '100%', gap: '0 25px' }}>
        <Box flex={1} style={{ alignSelf: 'center' }}>
          <TextField
            label="Name *"
            name="name"
            value={surveyName}
            onFocus={() => addTouched('name')}
            onChange={(e) => {
              setSurveyName(e.target.value);
            }}
            fullWidth
            error={touched?.current?.name && errors?.name}
            helperText={touched?.current?.name && errors?.name}
          />
        </Box>
        <Box flex={1} style={{ alignSelf: 'center', textAlign: 'center' }}>
          <InputLabel>Status</InputLabel>
          <FormControlLabel
            control={<Switch checked={status} onChange={toggleStatus} color="primary" />}
            label={t('surveys.edit.status.active')}
          />
        </Box>
        <Box flex={1} style={{ alignSelf: 'center', textAlign: 'center' }}>
          <InputLabel>Random</InputLabel>
          <FormControlLabel
            control={<Switch checked={random} onChange={toggleRandom} color="primary" />}
            label={t('surveys.edit.options.random')}
          />
        </Box>
      </Box>
      <h3 style={{ ...globalFont }}>{t('surveys.edit.questions')}</h3>
      <Questions {...props} />
      <div className={classes.addQuestionContainer}>
        <AddCircleIcon className={classes.pointer} onClick={() => createQuestion()} />
        <Typography onClick={() => createQuestion()}>Add question</Typography>
      </div>
      <ConfirmDelete {...props} />
    </>
  );
};

export default FormUi;
