import _ from 'lodash';

export const QUESTION_TYPES = {
  LIST: 'radio-checkbox',
};

export const selectTypeValues = [
  { id: '', name: '-' },
  { id: QUESTION_TYPES.LIST, name: 'Radio/Checkbox' },
];

export const typeDictionary = (key) => {
  switch (key) {
    case 'questionList':
      return QUESTION_TYPES.LIST;
    default:
      return undefined;
  }
};

const typeDictionaryForInput = (key) => {
  switch (key) {
    case QUESTION_TYPES.LIST:
      return 'questionList';
    default:
      return undefined;
  }
};

const parseInputByType = (input) => {
  switch (input.type) {
    case QUESTION_TYPES.LIST:
      return {
        id: input.id,
        answersAvailable: input?.answersAvailable?.map(answer => _.omit(answer, '__typename')),
        options: _.omit(input.options, '__typename'),
        title: input.title,
      };
    default:
      return null;
  }
};

export const parseQuestionsNodeInput = (input) => {
  if (input) {
    const parsedInput = [];

    input.map(item => parsedInput.push({
        [typeDictionaryForInput(item.type)]: {
          ...parseInputByType(item),
        },
      }));

    return parsedInput;
  }

  return input;
};

export const getDefaultValuesByType = (type) => {
  switch (type) {
    case QUESTION_TYPES.LIST:
    default:
      return {
        answersAvailable: [],
        title: '',
        options: {
          isMultiple: false,
        },
      };
  }
};

const validateSurveyQuestions = (questions) => {
  const errors = {};
  const zero = 0;

  questions.forEach((question) => {
    const key = Object.keys(question)[0];
    const value = question[key];

    // console.log('key', key);
    // console.log('value', value);
    if (key === 'questionList') {
      const { answersAvailable, id, title } = value;

      if (!answersAvailable || answersAvailable?.length === zero) {
        errors[`q_${id}`] = 'You must add at least one answer';
      }
      if (answersAvailable && answersAvailable.length > zero) {
        answersAvailable.forEach((answer) => {
          const { id: answerId, title: answerTitle } = answer;
          if (!answerTitle || answerTitle === '') {
            errors[`q_${id}_a_${answerId}`] = 'This field is mandatory';
          }
        });
      }
      if (!title || title === '') {
        errors[`q_${id}_title`] = 'This field is mandatory';
      }
    }
  });

  return errors;
};

export const validateSurveyForm = (data) => {
  const zero = 0;
  let output = {};

  if (data) {
    const { input } = data;

    if (!input?.name) {
      output.name = 'This field is mandatory';
    }

    if (!input?.questions || input?.questions.length === zero) {
      output.questions = 'You must add at least one question';
    }

    if (input?.questions && input?.questions?.length) {
      const questionsValidation = validateSurveyQuestions(input.questions);
      output = {
        ...output,
        ...questionsValidation,
      };
    }
  }

  return output;
};
