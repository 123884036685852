import { Loading, useDataProvider } from 'react-admin';
import React, { cloneElement, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';


const ProfileController = (props) => {
  const { id, children } = props;
  const [saveLoading, setSaveLoading] = useState(true);
  const [isCreated, setIsCreated] = useState(true);
  const [profile, setProfile] = useState({});
  const dataProvider = useDataProvider();
  const max = 9;

  useEffect(() => {
    dataProvider.getOne('profiles', { id })
      .then((response) => {
        setProfile(response.data);
        setSaveLoading(false);
      })
      .catch(() => {
        setSaveLoading(false);
      });
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (profile) {
      setIsCreated(Object.getOwnPropertyNames(profile).length <= max);
    }
  }, [profile]);

  if (saveLoading) { return <Loading />; }

  return (

    <Box p="1em">
      <Box display="flex" justifyContent="space-between" width="100%">
        {cloneElement(children, {
          record: {
            ...profile,
            userId: id,
            createdMode: isCreated,
          },
          setSaveLoading,
          setProfile,
        })}
      </Box>
    </Box>
  );
};

export default ProfileController;
