import * as React from 'react';

import {
  List, Datagrid, TextField, BooleanField,
} from 'react-admin';
import { EmptyList } from '../../lib/components';

const ProductList = props => (
  <List
    {...props}
    exporter={false}
    bulkActionButtons={false}
    empty={<EmptyList />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Survey name" sortable={false} />
      <TextField source="createdAt" sortable={false} />
      <BooleanField source="active" sortable={false} />
      <TextField source="totalAnswered" sortable={false} />
    </Datagrid>
  </List>
);

export default ProductList;
