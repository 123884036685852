import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  Edit,
  useEditController,
  Loading,
  useNotify,
  useDataProvider,
  Toolbar,
  SaveButton,
  RadioButtonGroupInput, TextInput,
} from 'react-admin';
import { useRefresh } from 'ra-core';
import Box from '@material-ui/core/Box';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { promotionFormStyles } from '../promotions/promotionFormStyles';
import { getEdition, getEnv } from '../../lib/utils';

const StaticEdit = ({ permissions, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [ready, setReady] = useState(false);
  const classes = promotionFormStyles();
  const { record: recordProp, loading, saving } = useEditController(props);
  const [content, setContent] = useState('');
  const record = recordProp;
  const platform = getEdition();
  const env = getEnv();

  const StaticToolBar = staticProps => (
    <Toolbar {...staticProps}>
      <SaveButton disabled={loading || saving} />
    </Toolbar>
  );

  const onSuccessActions = () => {
    notify('Content updated successfully', 'success');
    refresh();
  };

  const loadMarkdownContent = async () => {
    try {
      const response = await fetch(
        `https://monavis-pruebas.shopadvizor.net/scripts/pxy.php?env=${env}&platform=${platform}&file=${record.id}.html`,
      );
      if (!response.ok) {
        notify('There has been an error loading the content', 'error');
      }
      const jsonResponse = await response.json();
      const text = jsonResponse.response;
      setContent(text);
      setReady(true);
    } catch (error) {
      notify('There has been an error loading the content', 'error');
    }
  };

  useEffect(() => {
    if (record) {
      record.content = content;
    }
    /* eslint-disable-next-line */
  }, [content]);

  useEffect(() => {
    if (record?.id && !ready) {
      loadMarkdownContent();
    }
    /* eslint-disable-next-line */
  }, [record]);

  const handleSave = async (values) => {
    const blob = new Blob([content], { encoding: 'UTF-8', type: 'text/plain;charset=UTF-8' });

    try {
      await dataProvider.update('static', {
        path: `static-content/${platform}/${record.id}.html`,
        file: blob,
      }).then(() => {
        onSuccessActions();
      })
        .catch((error) => {
          notify(`Error: ${error.message}`, 'warning');
        });

      const response = await fetch(`https://monavis-pruebas.shopadvizor.net/scripts/pxy.php?env=${env}&platform=${platform}&file=index.json`);
      if (!response.ok) {
        throw new Error(`Error fetching the file: ${response.statusText}`);
      }
      const textResponse = await response.text();
      const parsedResponse = JSON.parse(textResponse).response;
      const parsedEntries = JSON.parse(parsedResponse);
      const existingEntries = Array.isArray(parsedEntries) ? parsedEntries : [];

      const entryIndex = existingEntries.findIndex(entry => entry.id === record.id);
      const newEntry = {
        id: record?.id,
        title: values.title,
        alias: values.alias,
        created: record?.created,
        author: record?.author,
        status: values.status,
        order: values.order,
      };
      const index = -1;
      if (entryIndex !== index) {
        existingEntries[entryIndex] = newEntry;
      } else {
        existingEntries.push(newEntry);
      }

      const jsonString = JSON.stringify(existingEntries);
      const indexBlob = new Blob([jsonString], { type: 'application/json;charset=UTF-8' });

      await dataProvider.create('static', {
        path: `static-content/${platform}/index.json`,
        file: indexBlob,
      });

      notify('Data saved successfully', 'success');
    } catch (error) {
      notify(`Error: ${error.message}`, 'warning');
    }
  };
  const buttonList = [
    ['undo', 'redo'],
    ['fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
    ['outdent', 'indent', 'align', 'horizontalRule', 'lineHeight'],
    ['list', 'table', 'link', 'image', 'showBlocks', 'preview'],
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <Edit {...props} title={`Statics: ${record.title}`}>
      <SimpleForm save={handleSave} toolbar={<StaticToolBar />}>
        <div className={classes.mainRow}>
          <div className={classes.rowCol}>
            <div className={classes.normalBoxLite}>
              <div className={classes.sectionTitle}>Information</div>
            </div>
            <div className={classes.normalBoxColumn}>
              <Box>
                <TextInput
                  source="title"
                  fullWidth
                  label="Title"
                />
              </Box>
              <Box>
                <TextInput
                  source="alias"
                  fullWidth
                  label="Alias"
                />
              </Box>
              <Box className={classes.normalBoxLite} style={{ gap: '0 15px' }}>
                <TextInput
                  source="author"
                  fullWidth
                  disabled
                  label="Author"
                />
                <TextInput
                  source="created"
                  fullWidth
                  disabled
                  label="Created date"
                />
              </Box>
            </div>
          </div>
          <div className={classes.rowCol}>
            <div className={classes.normalBoxLite}>
              <div className={classes.sectionTitle}>Status and Configuration</div>
            </div>
            <Box className={classes.normalBoxLite}>
              <RadioButtonGroupInput
                source="status"
                choices={[
                  { id: 'REJECTED', name: 'REJECTED' },
                  { id: 'PUBLISHED', name: 'PUBLISHED' },
                ]}
                fullWidth
                hiddenLabel
              />
            </Box>
            <Box className={classes.normalBoxLite}>
              <TextInput
                source="order"
                label="Order N#"
              />
            </Box>
          </div>
        </div>

        <div className={classes.mainRow}>
          <div className={classes.fullRowCol}>
            <div className={classes.normalBoxLite}>
              <div className={classes.sectionTitle}>Content</div>
            </div>
            <div className={classes.normalBoxFull}>
              <SunEditor
                setContents={content}
                onChange={data => setContent(data)}
                setOptions={{ buttonList }}
                setDefaultStyle="font-family: Noto Sans, Arial, Helvetica Neue, Helvetica, sans-serif;height: auto;min-height: 400px;"
              />
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};


export default StaticEdit;
